import type { UrlObject } from 'url'

export type Locale = (typeof locales)[number]
export const locales = ['pl', 'en', 'de', 'ar'] as const

export interface I18nRoutes {
  [key: string]: {
    [locale in Locale]?: string
  }
}

export type Url = UrlObject | string

declare global {
  interface Window {
    dataLayer: Record<string, any>[]
    gtm: Record<string, any>[]
  }
}
