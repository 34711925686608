// @ts-check
/**
 * @type {import('@/utils/i18n/types').I18nRoutes}
 **/
const routes = {
  '/contact': {
    en: '/contact',
    pl: '/kontakt',
    de: '/kontakt'
  },
  '/kontakt': {
    en: '/contact',
    pl: '/kontakt',
    de: '/kontakt'
  },
  '/parkings/[city]/[slug]': {
    en: '/parkings/[city]/[slug]',
    pl: '/parkingi/[city]/[slug]',
    de: '/parkplatze/[city]/[slug]'
  },
  '/parkingi/[city]/[slug]': {
    en: '/parkings/[city]/[slug]',
    pl: '/parkingi/[city]/[slug]',
    de: '/parkplatze/[city]/[slug]'
  },
  '/parkplatze/[city]/[slug]': {
    en: '/parkings/[city]/[slug]',
    pl: '/parkingi/[city]/[slug]',
    de: '/parkplatze/[city]/[slug]'
  },
  '/park-and-fly': {
    en: '/park-and-fly',
    pl: '/park-and-fly',
    de: '/parken-und-fliegen'
  },
  '/warsaw': {
    en: '/warsaw',
    pl: '/warszawa',
    de: '/warschau'
  },
  '/warszawa': {
    en: '/warsaw',
    pl: '/warszawa',
    de: '/warschau'
  },
  '/warschau': {
    en: '/warsaw',
    pl: '/warszawa',
    de: '/warschau'
  },
  '/parkings/[city]': {
    en: '/parkings/[city]',
    pl: '/parkingi/[city]',
    de: '/parkplatze/[city]'
  },
  '/parkingi/[city]': {
    en: '/parkings/[city]',
    pl: '/parkingi/[city]',
    de: '/parkplatze/[city]'
  },
  '/parkplatze/[city]': {
    en: '/parkings/[city]',
    pl: '/parkingi/[city]',
    de: '/parkplatze/[city]'
  },
  '/faq': {
    en: '/faq',
    pl: '/baza-wiedzy',
    de: '/wissensdatenbank'
  },
  '/wissensdatenbank': {
    en: '/faq',
    pl: '/baza-wiedzy',
    de: '/wissensdatenbank'
  },
  '/faq/search': {
    en: '/faq/search',
    pl: '/baza-wiedzy/szukaj',
    de: '/wissensdatenbank/suche'
  },
  '/baza-wiedzy/szukaj': {
    en: '/faq/search',
    pl: '/baza-wiedzy/szukaj',
    de: '/wissensdatenbank/suche'
  },
  '/wissensdatenbank/suche': {
    en: '/faq/search',
    pl: '/baza-wiedzy/szukaj',
    de: '/wissensdatenbank/suche'
  },
  '/faq/[category]': {
    en: '/faq/[category]',
    pl: '/baza-wiedzy/[category]',
    de: '/wissensdatenbank/[category]'
  },
  '/baza-wiedzy/[category]': {
    en: '/faq/[category]',
    pl: '/baza-wiedzy/[category]',
    de: '/wissensdatenbank/[category]'
  },
  '/wissensdatenbank/[category]': {
    en: '/faq/[category]',
    pl: '/baza-wiedzy/[category]',
    de: '/wissensdatenbank/[category]'
  },
  '/parkings': {
    en: '/parkings',
    pl: '/parkingi',
    de: '/parkplatze'
  },
  '/parkingi': {
    en: '/parking',
    pl: '/parkingi',
    de: '/parken'
  },
  '/parking': {
    en: '/parking',
    pl: '/parkingi',
    de: '/parken'
  },
  '/parken': {
    en: '/parking',
    pl: '/parkingi',
    de: '/parken'
  },
  '/parkplatze': {
    en: '/parkings',
    pl: '/parkingi',
    de: '/parkplatze'
  },
  '/poznań': {
    en: '/poznan',
    pl: '/poznań',
    de: '/poznan'
  },
  '/poznan': {
    en: '/poznan',
    pl: '/poznań',
    de: '/poznan'
  },
  '/platnosci': {
    en: '/payments',
    pl: '/platnosci',
    de: '/zahlungen'
  },
  '/zahlungen': {
    en: '/payments',
    pl: '/platnosci',
    de: '/zahlungen'
  },
  '/payments': {
    en: '/payments',
    pl: '/platnosci',
    de: '/zahlungen'
  },
  '/rezerwacje': {
    en: '/reservations',
    pl: '/rezerwacje',
    de: '/buchungen'
  },
  '/buchungen': {
    en: '/reservations',
    pl: '/rezerwacje',
    de: '/buchungen'
  },
  '/reservations': {
    en: '/reservations',
    pl: '/rezerwacje',
    de: '/buchungen'
  },
  '/abonamenty': {
    en: '/subscriptions',
    pl: '/abonamenty',
    de: '/abonnements'
  },
  '/subscriptions': {
    en: '/subscriptions',
    pl: '/abonamenty',
    de: '/abonnements'
  },
  '/abonnements': {
    en: '/subscriptions',
    pl: '/abonamenty',
    de: '/abonnements'
  },
  '/aplikacja-navipay': {
    en: '/navipay-application',
    pl: '/aplikacja-navipay',
    de: '/navipay-app'
  },
  '/navipay-application': {
    en: '/navipay-application',
    pl: '/aplikacja-navipay',
    de: '/navipay-app'
  },
  '/navipay-app': {
    en: '/navipay-application',
    pl: '/aplikacja-navipay',
    de: '/navipay-app'
  },
  '/parkhauser-parkplatze': {
    en: '/parking',
    pl: '/parkowanie',
    de: '/parkhauser-parkplatze'
  },
  '/parkowanie': {
    en: '/parking',
    pl: '/parkowanie',
    de: '/parkhauser-parkplatze'
  },
  '/katowice': {
    en: '/katowice',
    pl: '/katowice',
    de: '/kattowitz'
  },
  '/kattowitz': {
    en: '/katowice',
    pl: '/katowice',
    de: '/kattowitz'
  },
  '/krakow': {
    en: '/krakow',
    pl: '/krakow',
    de: '/krakau'
  },
  '/kattowitz-pyrzowice': {
    en: '/katowice-pyrzowice',
    pl: '/katowice-pyrzowice',
    de: '/kattowitz-pyrzowice'
  },
  '/katowice-pyrzowice': {
    en: '/katowice-pyrzowice',
    pl: '/katowice-pyrzowice',
    de: '/kattowitz-pyrzowice'
  },
  '/swinemünde': {
    en: '/swinoujscie',
    pl: '/swinoujscie',
    de: '/swinemünde'
  },
  '/swinoujscie': {
    en: '/swinoujscie',
    pl: '/swinoujscie',
    de: '/swinemünde'
  },
  '/danzig': {
    en: '/gdansk',
    pl: '/gdansk',
    de: '/danzig'
  },
  '/gdansk': {
    en: '/gdansk',
    pl: '/gdansk',
    de: '/danzig'
  },
  '/breslau': {
    en: '/wroclaw',
    pl: '/wroclaw',
    de: '/breslau'
  },
  '/wroclaw': {
    en: '/wroclaw',
    pl: '/wroclaw',
    de: '/breslau'
  }
}

module.exports = routes
