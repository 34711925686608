import 'focus-visible'
import { install } from 'resize-observer'
import 'mapbox-gl/dist/mapbox-gl.css'

import { useState, type ReactElement, type ReactNode } from 'react'
import type { NextPage } from 'next'
import { Global } from '@emotion/react'
import Head from 'next/head'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { UserConfig, appWithTranslation } from 'next-i18next'

import vh from '@/utils/100vh'
import { GlobalStyles } from '@/styles/global'
import { MarketProvider } from '@/utils/multi-markets/context'
import { I18nParamsProvider } from '@/utils/i18n/i18n-params-context'
import EnhancedRouterProvider from '@/utils/i18n/enhanced-router'

import nextI18NextConfig from '../next-i18next.config.js'

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<
  P,
  IP
> & {
  getLayout?: (page: ReactElement) => ReactNode
}

interface PageProps {
  i18nParams?: any
  clientDetails?: any
  market?: string
  core?: any
  pageProps: any
}

interface AppPropsWithLayout extends PageProps {
  Component: NextPage & { getLayout?: (page: ReactElement) => ReactNode }
}

if (typeof window !== 'undefined') {
  install()
  vh.init()
}

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <>
      <Global styles={GlobalStyles} />
      <Head>
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="https://cdn.naviparking.com/digital_parking/img/apple-touch-icon.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="https://cdn.naviparking.com/digital_parking/img/favicon-32x32.webp"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="https://cdn.naviparking.com/digital_parking/img/favicon-16x16.webp"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#3b8ded" />
        <meta name="msapplication-TileColor" content="#3b8ded" />
        <meta name="theme-color" content="#3b8ded" />
        <meta
          name="google-site-verification"
          content="Y946D897s6gPeX-FtRqoX3YW1fI77HDK2-ycchbP-Y8"
        />
        {pageProps.market === 'pl' && (
          <meta
            name="facebook-domain-verification"
            content="wb4f2l7z4evdgj7wcqfcshjacd0wqu"
          />
        )}
        {pageProps.market === 'ae' && (
          <meta
            name="facebook-domain-verification"
            content="8sv3d8j9glrnrnrqehy3bbrpyrg54g"
          />
        )}
      </Head>
      <EnhancedRouterProvider pageProps={pageProps}>
        <I18nParamsProvider i18nParams={pageProps.i18nParams}>
          <MarketProvider market={pageProps.market}>
            <QueryClientProvider client={queryClient}>
              <Component {...pageProps} />
            </QueryClientProvider>
          </MarketProvider>
        </I18nParamsProvider>
      </EnhancedRouterProvider>
    </>
  )
}

export default appWithTranslation(
  App,
  nextI18NextConfig as unknown as UserConfig
)
