import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'

import { useRouter } from 'next/router'

import { Market, Markets } from '@/utils/multi-markets/types'
import { Locale, locales } from '@/utils/i18n/types'
import { getMarketByHost } from '@/utils/multi-markets/common'

export interface MarketContext {
  market: Market
  markets: Markets
  host: string
  defaultLocale: Locale
  fallback?: boolean
}

const MarketContext = createContext<MarketContext | undefined>(undefined)

interface MarketProviderProps {
  market: MarketContext
  children: ReactNode
}

export function MarketProvider(props: MarketProviderProps) {
  const { market, children } = props
  const [clientSideContext, setClientSideContext] = useState<MarketContext>()
  const value = useMemo(() => ({ ...market }), [market])

  // When context has fallback flag (host wasn't detected server-side)
  // try to get it client side
  useEffect(() => {
    if (market.fallback && window?.location) {
      const matchedMarket = getMarketByHost(
        window.location.host,
        market.markets
      )
      setClientSideContext({
        market: market.market,
        markets: market.markets,
        host: market.markets[matchedMarket].host,
        defaultLocale: market.markets[matchedMarket].defaultLocale
      })
    }
  }, [market?.fallback, market?.market, market?.markets])

  return (
    <MarketContext.Provider value={clientSideContext ?? value}>
      {children}
    </MarketContext.Provider>
  )
}

export function useMarket() {
  const context = useContext(MarketContext)
  const { locale } = useRouter()

  if (context === undefined) {
    throw new Error('useMarket must be used within a MarketProvider')
  }

  return {
    ...context,
    locale: (locale ?? context.defaultLocale) as Locale,
    locales
  }
}
