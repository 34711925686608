import { rgba } from 'polished'

// Colors
export const colors = {
  // Neutral
  black: '#373737',
  white: '#FFFFFF',

  // Primary
  twilightBlue: '#093769',
  darkSkyBlue: '#3b8ded',
  blueHover: '#347bcf',
  blueLight: '#c9e0fa',
  paleGrey: '#f2f6fd',
  cloudyBlue: '#c1c5c9',
  blueyGrey: '#6C7075',
  battleshipGrey: '#71757a',
  darkCoral: '#d94343',
  greenishCyan: '#3deabf',
  greenishCyanHover: '#36d8b0',
  paleHover: '#deedff',
  pinkRed: '#d81463'
}
export type Colors = keyof typeof colors

// Typography
export const weights = {
  semibold: 600,
  black: 900
}
export type Weights = keyof typeof weights

export const fonts = {
  primary: 'Nunito, sans-serif',
  arabic: 'Cairo, sans-serif'
}
export type Fonts = keyof typeof fonts

export const gradients = {
  hero: `linear-gradient(
      90deg,
      ${rgba(colors.paleGrey, 1)} 0%,
      ${rgba(colors.paleGrey, 1)} 35%,
      ${rgba(colors.paleGrey, 0)} 80%
    );`,
  heroAR: `linear-gradient(
      -90deg,
      ${rgba(colors.paleGrey, 1)} 0%,
      ${rgba(colors.paleGrey, 1)} 35%,
      ${rgba(colors.paleGrey, 0)} 80%
    );`,
  heroMobile: `linear-gradient(
      180deg,
      ${rgba(colors.paleGrey, 1)} 0%,
      ${rgba(colors.paleGrey, 1)} 65%,
      ${rgba(colors.paleGrey, 0)} 100%
    );`
}
export type Gradients = keyof typeof gradients

// Border
export const border = {
  borderColor: rgba(colors.twilightBlue, 0.08),
  radius: '27px',
  radiusMobile: '15px',
  shadow: `0 4px 24px 0 ${rgba(colors.twilightBlue, 0.08)}`,
  shadowMenu: `0 2px 12px 0 ${rgba(colors.twilightBlue, 0.16)}`
}
export type Border = keyof typeof border

// Transition
export const transitions = {
  base: '0.2s',
  image: '0.4s ease-out',
  imageHero: '0.2s ease-out'
}

export type Transitions = keyof typeof transitions

// Vars
export const vars = {
  headerHeight: '138px',
  headerHeightMobile: '64px',
  whiteOpacity: rgba(colors.white, 0.33),
  whiteOpacityActive: rgba(colors.white, 0.8)
}

export type Vars = keyof typeof vars

// Z-Index
export const zIndex = {
  header: '80',
  burger: '90',
  cookies: '100',
  modal: '110',
  modalWrapper: '109'
}

export type ZIndex = keyof typeof zIndex
