import React, { useMemo } from 'react'
import { AppProps } from 'next/app'
import { NextRouter, useRouter as useNextRouter } from 'next/router'
import { RouterContext } from 'next/dist/shared/lib/router-context'

import { MarketContext } from '@/utils/multi-markets/context'
import { Locale, Url } from '@/utils/i18n/types'
import {
  dynamicRouteFromQuery,
  translatePathname
} from '@/utils/multi-markets/common'

export function toTranslatedLink(
  url: Url,
  as: Url | undefined,
  currentPathname: string,
  locale: Locale,
  defaultLocale: Locale
) {
  const pathname =
    (typeof url === 'object' ? url.pathname : url) ?? currentPathname

  // Translate all links by default, omit translation when:
  // - there is `as` defined
  // - link doesn't start with slash (external or incorrect internal link)
  const shouldTranslateLink = as === undefined && pathname.startsWith('/')

  if (shouldTranslateLink) {
    // Due to next.js limitations dynamic route params are not automatically
    // replaced in `as` parameter
    const dynamicRoute = dynamicRouteFromQuery(
      translatePathname(pathname, locale, defaultLocale),
      typeof url === 'object' ? url.query : undefined
    )

    return {
      href: url,
      as: {
        ...(typeof url === 'object' && url),
        pathname: dynamicRoute.pathname,
        query: dynamicRoute.query
      },
      options: { locale: false as const }
    }
  } else {
    return { href: url, as }
  }
}

function enhanceNextRouter(
  router: NextRouter,
  pageProps: AppProps['pageProps']
): NextRouter {
  //@ts-ignore
  const market: MarketContext = pageProps?.market

  const locale =
    router.locale === 'default' || !router.locale
      ? market.defaultLocale
      : (router.locale as Locale)

  // Custom methods
  const push: NextRouter['push'] = (url, as, options) => {
    const link = toTranslatedLink(
      url,
      as,
      router.pathname,
      typeof options?.locale === 'string' ? (options.locale as Locale) : locale,
      market.defaultLocale
    )

    return router.push(link.href, link.as, { ...options, ...link.options })
  }

  const replace: NextRouter['replace'] = (url, as, options) => {
    const link = toTranslatedLink(
      url,
      as,
      router.pathname,
      locale,
      market.defaultLocale
    )

    return router.replace(link.href, link.as, { ...options, ...link.options })
  }

  return {
    ...router,
    locale,
    push,
    replace
  }
}

type Props = React.PropsWithChildren<Pick<AppProps, 'pageProps'>>

// TODO: Add test
function EnhancedRouterProvider(props: Props) {
  const { pageProps, children } = props
  const nextRouter = useNextRouter()

  const enhancedRouter = useMemo(
    () => enhanceNextRouter(nextRouter, pageProps),
    [nextRouter, pageProps]
  )

  return (
    <RouterContext.Provider value={enhancedRouter}>
      {children}
    </RouterContext.Provider>
  )
}

export default EnhancedRouterProvider
