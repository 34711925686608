import { createContext, ReactNode, useContext, useMemo } from 'react'

import { Locale } from '@/utils/i18n/types'

export interface I18nParams {
  [key: string]: {
    [locale in Locale]?: string | string[]
  }
}

const I18nParamsContext = createContext<{ i18nParams: I18nParams } | undefined>(
  undefined
)

interface I18nParamsProvider {
  i18nParams: I18nParams
  children: ReactNode
}

export function I18nParamsProvider(props: I18nParamsProvider) {
  const { i18nParams, children } = props

  const value = useMemo(() => ({ i18nParams }), [i18nParams])

  return (
    <I18nParamsContext.Provider value={value}>
      {children}
    </I18nParamsContext.Provider>
  )
}

export function useI18nParams() {
  const context = useContext(I18nParamsContext)

  if (context === undefined) {
    throw new Error('useI18nParams must be used within a I18nParamsContext')
  }

  return context
}
