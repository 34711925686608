import { ParsedUrlQuery } from 'querystring'

import { UrlObject } from 'url'

import { I18nParams } from '@/utils/i18n/i18n-params-context'
import { Locale } from '@/utils/i18n/types'
import { Market, MarketEntry, Markets } from '@/utils/multi-markets/types'
import routes from '@/i18n-routes.config'
import { first } from '@/utils/common'

export function translatePathname(
  pathname: string,
  locale: Locale,
  defaultLocale: Locale
): string {
  const path = routes[pathname]?.[locale] ?? pathname
  const prefix = defaultLocale === locale ? '' : `/${locale}`

  return prefix + path
}

export function toAbsoluteURL(
  pathname: string,
  locale: Locale,
  market: MarketEntry
): string {
  return (
    'https://' +
    market.host +
    translatePathname(pathname, locale, market.defaultLocale)
  )
}

export function i18nParamsForLocale(
  query: ParsedUrlQuery,
  i18nParams: I18nParams,
  locale: Locale
): ParsedUrlQuery {
  const params = { ...query }

  if (i18nParams) {
    for (const param in i18nParams) {
      params[param] = i18nParams[param][locale]
    }
  }
  for (const path in params) {
    const value = `/${params[path] as string}`

    if (routes[value] && routes[value][locale]) {
      params[path] = routes[value][locale]?.slice(1)
    }
  }

  return params
}

// TODO: Use path-to-regexp
export function dynamicRouteFromQuery(
  pathname: string,
  query?: UrlObject['query']
): { pathname: string; query?: UrlObject['query'] } {
  if (typeof query !== 'object' || query === null) return { pathname, query }

  let parsedPathname = pathname
  const dynamicParams: string[] = []

  for (const param in query) {
    // TODO: Handle catch-all and optional catch-all
    parsedPathname = parsedPathname.replace(
      new RegExp(`\\[${param}\\]`, 'g'),
      () => {
        // Save dynamic param, so it can be removed from query later
        dynamicParams.push(param)
        // TODO: Tweak types
        return String(first(query[param] as string) ?? '')
      }
    )
  }

  return {
    pathname: parsedPathname,
    // Filter out every query param that was used as dynamic parameter
    query: Object.fromEntries(
      Object.entries(query).filter(([param]) => !dynamicParams.includes(param))
    )
  }
}

export const fallbackMarket: Market = 'pl'

export function getMarketByHost(host: string, markets: Markets): Market {
  const market = Object.entries(markets).find(
    ([, value]) => value.host === host
  )

  return market ? (market[0] as Market) : fallbackMarket
}
