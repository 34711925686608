import { useTranslation } from 'next-i18next'

import { ColorScheme } from '@/service'

import { useMarket } from './multi-markets/context'
/**
 * Gets a first element of an array safely
 */
export function first<T>(value: T | T[]): T {
  return Array.isArray(value) ? value[0] : value
}

export const convertBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export function removeSpaces(value: string) {
  return value.replace(/\s/g, '')
}

export function isImage(url: string) {
  return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg|heic)$/.test(
    url.toLowerCase()
  )
}

export function slugify(text: string) {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s/g, '-')
}

export function removeHtml(url: string) {
  return url.replace(/(<([^>]+)>)/gi, '')
}

export function setCssVars(colors: ColorScheme, suffix?: string) {
  if (colors) {
    Object.entries(colors).map(el => {
      document.documentElement.style.setProperty(
        `--${el[0]}${suffix ? suffix : ''}`,
        el[1]
      )
    })
  }
}

type ReverseDateReturnType = {
  year: number
  month: number
  day: number
}

export function reverseDate(date: string): ReverseDateReturnType {
  const parse = date.split('-')
  return {
    year: parseInt(parse[0]),
    month: parseInt(parse[1]) - 1,
    day: parseInt(parse[2])
  }
}

export const formatPrice = (
  amount: number,
  currency: string,
  locale: any,
  t: any
) => {
  const formattedAmount =
    locale === 'ar' ? new Intl.NumberFormat('ar-EG').format(amount) : amount

  const currencyText = t(`common:currency${currency}` as any)
  return `${formattedAmount} ${currencyText}`
}
