export type Breakpoints = keyof typeof breakpoints

export const breakpoints = {
  xs: 0,
  sm: 360,
  md: 768,
  lg: 1280,
  xl: 1440
}

export function mediaUp(key: Breakpoints | number): string {
  const width = typeof key === 'number' ? key : breakpoints[key]
  return `@media (min-width: ${width}px)`
}

export function mediaDown(key: Breakpoints | number): string {
  const width = typeof key === 'number' ? key : breakpoints[key]
  return `@media (max-width: ${width - 1}px)`
}
